import { mapGetters } from "vuex";
import Loader from "@/view/content/Loader.vue";
import { ADD_BODY_CLASSNAME } from "@/store/common/htmlclass.module.js";

export default {
  name: "AuthRedirect",
  components: {
    Loader
  },
  beforeCreate() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
  },
  async created() {
    if (this.isAuthenticated) {
      await this.$router.replace({ name: "dashboard" });
    } else {
      await this.$store.dispatch("signIn").then(validUser => {
        if (validUser) {
          this.$router.replace({
            name: "dashboard"
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "isAuthenticated"]),

    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    }
  }
};
